<template>
  <div class="topicPage">
    <HeadBar :titleName="titleName"></HeadBar>
    <div class="content oepnX">
      <van-tabs
        class="navTabs oepnX"
        swipeable
        animated
        line-width="24"
        color="rgb(113,69,231)"
        line-height="6"
        v-model="activeName"
        background="rgba(0,0,0,0)"
        v-if="navList && navList.length"
        @change="clickTag"
      >
        <van-tab v-for="(item, index) in navList" :key="index">
          <template #title>
            <span
              class="navTabTitle"
              :class="{ navTabTitleActive: activeName == index }"
            >
              {{ item.title }}
            </span>
          </template>
          <div class="topicPageContent">
            <PullRefresh
              v-if="!isLoading"
              :loading="loading"
              :refreshing="refreshing"
              :finished="finished"
              @onLoad="onLoad"
              @onRefresh="onRefresh"
              :isNoData="isNoData"
              :error="error"
              :is-higeht-max="true"
              :adv-list="advList"
              :hasAdvPagination="true"
              className="TopicPage"
            >
              <VerticleThreeList
                :showAdv="true"
                :videoList="videoList"
                v-if="format == 0 && videoList.length"
              >
              </VerticleThreeList>
              <TransverselyTwoList
                :videoList="videoList"
                v-else
              ></TransverselyTwoList>
            </PullRefresh>
            <Loading v-else />
          </div>
        </van-tab>
      </van-tabs>
    </div>
  </div>
</template>

<script>
import VerticleThreeList from "@/components/VideoList/VerticleThreeList";
import TransverselyTwoList from "@/components/VideoList/TransverselyTwoList";
import { AdType, getAdItem, jumpAdv } from "@/utils/getConfig";
import { queryTopicMediaList } from "@/api/video";
import { moduleListMore } from "@/api/home";
import Loading from "@/components/Loading";
import PullRefresh from "@/components/PullRefresh";
import HeadBar from "@/components/HeadBar";
export default {
  name: "TopicPage",
  components: {
    VerticleThreeList,
    TransverselyTwoList,
    Loading,
    PullRefresh,
    HeadBar,
  },
  data() {
    return {
      activeName: 0,
      pageNumber: 1,
      pageSize: 12,
      topicId: "",
      zone: "",
      titleName: "",
      format: 0,
      tabNmae: "",
      name: "",
      advNum: 0,
      videoList: [],
      oldVideoList: [],
      listAdvs: [],
      listAdvsVerticel: [],
      isLoading: true,
      loading: true,
      refreshing: false,
      finished: false,
      isNoData: false,
      advList: [],
      error: false,
      navList: [
        {
          title: "最新 / ",
          key: 1,
        },
        {
          title: "推荐 / ",
          key: 2,
        },
        {
          title: "逛逛 / ",
          key: 3,
        },
        {
          title: "热榜 / ",
          key: 4,
        },
      ],
    };
  },
  created() {
    // console.log('初始化')
    this.listAdvs = getAdItem(AdType.TIPIC_MORE);
    this.listAdvsVerticel = getAdItem(AdType.TIPIC_MORE_VERTICLE);
  },
  mounted() {
    const { topicId, zone, name, format, tabNmae } = this.$route.query;
    if (topicId) {
      this.topicId = topicId;
      this.zone = zone;
      this.titleName = name;
      this.format = format;
      this.tabNmae = tabNmae;
      this.name = name;
      this.getTopicMediaList("refresh");
      // console.log(this.zone,this.tabNmae,this.name);
    }
  },
  methods: {
    async getTopicMediaList(type) {
      let req = {
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
        zone: this.zone,
        simpleSort: this.navList[this.activeName].key,
        topicID: this.topicId,
      };
      let res;
      const nameArr = ["最新", "禁播", "真人"];
      // const original = ["有声漫", "小师妹", "国语里番"];
      // const original = [];
      // original.push(this.name);
      // console.log(nameArr.includes(this.zone))
      // res = await this.$Api(queryTopicMediaList, req);
      // if (nameArr.includes(this.tabNmae) && this.tabNmae != this.zone) {
      if (
        (nameArr.includes(this.zone) && this.zone == "最新") ||
        this.zone == "真人"
      ) {
        res = await this.$Api(queryTopicMediaList, req);
      } else if (this.tabNmae == "漫画") {
        req.title = "漫画";
        res = await this.$Api(queryTopicMediaList, req);
      } else if (this.tabNmae == "涩漫剧场") {
        req.title = "涩漫天堂";
        res = await this.$Api(queryTopicMediaList, req);
      } else if (this.tabNmae == "动漫" && this.zone !== "精选") {
        req.title = "动漫";
        res = await this.$Api(queryTopicMediaList, req);
      } else if (this.tabNmae == "动漫" && this.zone == "精选") {
        res = await this.$Api(queryTopicMediaList, req);
      } else if (this.$route.query.title) {
        req.title = this.$route.query.title;
        res = await this.$Api(queryTopicMediaList, req);
      } else {
        res = await this.$Api(queryTopicMediaList, req);
      }
      this.loading = false;
      this.refreshing = false;
      this.isLoading = false;
      try {
        if (res && res.code == 200) {
          var arr = res.data.MediaList || [];
          if (type == "refresh") {
            // this.videoList = res.data.MediaList;
            this.oldVideoList = arr;
          } else {
            this.oldVideoList = this.oldVideoList.concat(arr);
          }

          if (this.listAdvs.length > 0 || this.listAdvsVerticel.length > 0) {
            let advNum = Math.floor(this.oldVideoList.length / 4);
            let advList =
              this.format == 0
                ? this.listAdvs || []
                : this.listAdvsVerticel || [];
            let advLength = advList.length;
            this.videoList = JSON.parse(JSON.stringify(this.oldVideoList));
            // let advLength =
            //   this.format == 0
            //     ? this.listAdvs.length
            //     : this.listAdvsVerticel.length;
            if (advLength) {
              for (let i = 0; i < advNum; i++) {
                let advItem =
                  this.format == 0
                    ? this.listAdvs[i % advLength]
                    : this.listAdvsVerticel[i % advLength];
                this.videoList.splice(5 * i + 4, 0, {
                  adv: advItem,
                });
              }
            }
          } else {
            this.videoList = [].concat(this.oldVideoList || []);
          }
          // console.log(this.videoList, "========");

          // this.AdvNum = AdvNum;

          if (
            this.pageNumber === 1 &&
            this.videoList &&
            this.videoList.length === 0
          ) {
            this.isNoData = true;
          }
          if (!res.data.hasNext || res.data.MediaList.length < req.pageSize) {
            this.finished = true;
          }
        } else {
          this.error = true;
        }
      } catch (error) {
        this.error = true;
        this.loading = false;
      }
    },
    onRefresh() {
      this.pageNumber = 1;
      this.refreshing = true;
      this.finished = false;
      this.isNoData = false;
      this.loading = true;
      this.getTopicMediaList("refresh");
    },
    onLoad() {
      this.loading = true;
      if (this.error) {
        this.error = false;
      } else {
        this.pageNumber++;
      }
      this.getTopicMediaList();
    },
    clickTag(index) {
      if (index != null) {
        this.activeName = index;
      }
      this.pageNumber = 1;
      this.finished = false;
      this.loading = true;
      this.isLoading = true;
      this.isNoData = false;
      this.getTopicMediaList("refresh");
    },
  },
  watch: {
    $route(to, from) {
      if (to.path == "/topicPage") {
        if (to.query) {
          if (this.topicId != to.query.topicId) {
            this.topicId = to.query["topicId"];
            this.zone = to.query["zone"];
            this.titleName = to.query["name"];
            this.format = to.query["format"];
            this.activeName = 0;
            this.isLoading = true;
            this.getTopicMediaList("refresh");
          }
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.topicPage {
  height: 100%;
  /deep/ .headBar {
    .headTitle {
      font-size: 16px;
      color: #333333;
    }
  }
  .content {
    //width: 100%;
    // height: calc(100% - 44px);
    //padding: 0 12px;
    background: #ffffff;
    /deep/ .van-tabs__wrap {
      padding-top: 15px;
      height: 30px !important;
    }
    /deep/ .van-tab {
      display: contents;
    }

    /deep/ .van-tab__text {
      margin-right: 5px !important;
    }

    /deep/ .van-tabs__nav--line {
      // width: 188px;
      // padding-left: 9px;
      padding-left: 14px !important;
    }

    .navTabTitle {
      color: rgb(153, 153, 153);
    }

    .navTabTitleActive {
      color: rgb(252, 65, 98);
    }

    .topicPageContent {
      height: calc(100vh - 48px - 44px);
      padding: 0 12px;
    }
  }
  .navTabs {
    /deep/ .van-tabs__line {
      display: none !important;
    }
  }
}
</style>
